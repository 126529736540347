
.pdp-accordion-container {
    // Default
    &.default{
        .pdp-accordion-title{
            display: flex;
            justify-content: space-between;
            align-items: center;

            .pdp-accordion-title-icon{
                font-size 14px
                padding 6px
            }
        }
        ::v-deep {
            .v-expansion-panel::before {
                box-shadow: none;
            }

            .v-expansion-panels .v-expansion-panel::after {
                border: none;
            }

            .v-expansion-panels .v-expansion-panel:not(:last-child):not(:first-child) {
                border-bottom: 1px solid rgba(0,0,0,.08);
                border-right: none;
                border-left: none;
            }

            .v-expansion-panel-header{
                padding: 13.50px 0;
                min-height: fit-content;
            }

            .v-expansion-panel-content .v-expansion-panel-content__wrap{
                padding: 0 0 16px 0;
            }

            .v-expansion-panels .v-expansion-panel:first-child {
                border-top: 1px solid rgba(0,0,0,.08);
                border-bottom: 1px solid rgba(0,0,0,.08);
            }

            .v-expansion-panels .v-expansion-panel:last-child {
                border-bottom: 1px solid rgba(0,0,0,.08);
            }

            .v-expansion-panel-header {
                color: rgba(18,18,18,.75);

                .pdp-accordion-title-icon{
                    color: rgba(18,18,18,.75);
                }
            }

            .v-item--active .v-expansion-panel-header {
                color: $color-bnn !important;

                .pdp-accordion-title-icon{
                    color: $color-bnn;
                    transform: rotate(180deg);
                }
            }
        }
    }

    // flat
    &.flat{
        // Default
        .pdp-accordion-title{
            display: flex;
            justify-content: space-between;
            align-items: center;

            .pdp-accordion-title-icon{
                font-size 14px
                padding 6px
            }
        }
        ::v-deep {
            .v-expansion-panel::before {
                box-shadow: none;
            }

            .v-expansion-panel {
                border: none;
            }

            .v-expansion-panels .v-expansion-panel::after {
                border: none;
            }

            .v-expansion-panel-header{
                padding: 13.50px 0;
                min-height: fit-content;
            }

            .v-expansion-panel-content .v-expansion-panel-content__wrap{
                padding: 0 0 16px 0;
            }

            .v-expansion-panel-header {
                color: rgba(18,18,18,.75);

                .pdp-accordion-title-icon{
                    color: rgba(18,18,18,.75);
                }
            }

            .v-item--active .v-expansion-panel-header {
                color: $color-bnn !important;

                .pdp-accordion-title-icon{
                    color: $color-bnn;
                    transform: rotate(180deg);
                }
            }
        }
    }
}
